.Toastify__toast-container--top-right {
    top: calc(env(safe-area-inset-top, 0px) + 75px);
}

@media screen and (max-width: 1500px) {
    .Toastify__toast-container--top-right {
        top: calc(env(safe-area-inset-top, 0px) + 125px);
    }
}

@media screen and (max-width: 768px) {
    .Toastify__toast-container--top-right {
        width: calc(100% - 40px);
        margin: 0 20px;
    }
}

.Toastify__toast {
    min-height: 50px;
    min-width: 320px;
    width: auto;
    margin-bottom: 10px;
    padding: 2px 25px 2px 10px;
    font-size: 14px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: #ff9800;
}