.ol-popup {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 1px 4px rgba(0,0,0,0.3);
  padding: 15px 30px 15px 15px;
  border-radius: 10px;
  bottom: 18px;
  left: -48px;
  width: max-content;
  max-width: 270px;
}

@media screen and (max-width: 768px) {
  .ol-popup {
    max-width: 200px;
  }
}

.ol-popup:after, .ol-popup:before {
  top: calc(100% - 1px);
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: rgba(255, 255, 255, 0.95);
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 3px;
  right: 3px;
  color: #333333;
}