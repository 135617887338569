.ol-zoom {
  top: calc(100% - .5em*2 - 2.375em*2);
  left: calc(100% - .5em*2 - 1.375em);
}

.ol-scale-line {
  left: calc(100% - .5em*2 - 8.375em);
}

.ol-rotate {
  top: calc(env(safe-area-inset-top, 0px) + 24px);
  right: calc(env(safe-area-inset-right, 0px) + 76px); 
}

.ol-mouse-position {
  position: absolute;
  right: unset;
  top: unset;
  bottom: 5px;
  left: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  padding: 2px 5px 3px 5px;
}