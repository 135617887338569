body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ajout pour impression (permet de cacher différents élément lors de l'impression) */
@media print {
  button {
    display: none !important;
  }
  .react-geo-layer-switcher {
    display: none !important;
  }
  .SearchBox {
    display: none !important;
  }
  .toolsDrawer {
    display: none !important;
  }
  .sideBar {
    display: none !important;
  }
  .SnackbarContainer-root {
    display: none !important;
  }
  .ol-mouse-position {
    display: none !important;
  }
  #timeline {
    display: none !important;
  }
}
