.weather-scale {
  font-size: 11px;
  text-shadow: 0px 0px 4px black;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.weather-scale-unit {
  width: 100%;
  height: 18px;
  line-height: 16px;
  text-align: center;
}

.weather-scale-step {
  width: 100%;
  height: 18px;
}

.weather-scale-value {
  position: absolute;
  width: 100%;
  height: 18px;
  line-height: 16px;
  text-align: center;
}